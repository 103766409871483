
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.default || mod) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
  }
}
import { default as academyWMW5PTXKDhMeta } from "/home/runner/work/mp-landing-pages/mp-landing-pages/pages/account/academy.vue?macro=true";
import { default as indexPuPRFpnlO5Meta } from "/home/runner/work/mp-landing-pages/mp-landing-pages/pages/account/business/index.vue?macro=true";
import { default as collectionsLdorU2Gqg3Meta } from "/home/runner/work/mp-landing-pages/mp-landing-pages/pages/account/collections.vue?macro=true";
import { default as downloadsJcYCPpPBPzMeta } from "/home/runner/work/mp-landing-pages/mp-landing-pages/pages/account/downloads.vue?macro=true";
import { default as indexPc36yBXA6NMeta } from "/home/runner/work/mp-landing-pages/mp-landing-pages/pages/account/index.vue?macro=true";
import { default as subscriptionIy9DX6KglyMeta } from "/home/runner/work/mp-landing-pages/mp-landing-pages/pages/account/subscription.vue?macro=true";
import { default as barnBDMS7Z91jMeta } from "/home/runner/work/mp-landing-pages/mp-landing-pages/pages/bar.vue?macro=true";
import { default as _91sessionId_93YMKaSYjgSaMeta } from "/home/runner/work/mp-landing-pages/mp-landing-pages/pages/checkout/[sessionId].vue?macro=true";
import { default as foooU8UEkGR7nMeta } from "/home/runner/work/mp-landing-pages/mp-landing-pages/pages/foo.vue?macro=true";
import { default as forgot_45passwordIf4MhASxPXMeta } from "/home/runner/work/mp-landing-pages/mp-landing-pages/pages/forgot-password.vue?macro=true";
import { default as hdriskGslmyDepxMeta } from "/home/runner/work/mp-landing-pages/mp-landing-pages/pages/hdris.vue?macro=true";
import { default as indexzOF9HdvkSOMeta } from "/home/runner/work/mp-landing-pages/mp-landing-pages/pages/index.vue?macro=true";
import { default as licensing0tSgmrfYPcMeta } from "/home/runner/work/mp-landing-pages/mp-landing-pages/pages/licensing.vue?macro=true";
import { default as indexkpUfc7YEL6Meta } from "/home/runner/work/mp-landing-pages/mp-landing-pages/pages/login/index.vue?macro=true";
import { default as ssoHB07HGkx3hMeta } from "/home/runner/work/mp-landing-pages/mp-landing-pages/pages/login/sso.vue?macro=true";
import { default as logout6NR3Dxn1VZMeta } from "/home/runner/work/mp-landing-pages/mp-landing-pages/pages/logout.vue?macro=true";
import { default as _91planId_936uD9PlsQHtMeta } from "/home/runner/work/mp-landing-pages/mp-landing-pages/pages/plans/[planId].vue?macro=true";
import { default as commercial5lonxrzXCKMeta } from "/home/runner/work/mp-landing-pages/mp-landing-pages/pages/plans/commercial.vue?macro=true";
import { default as indexMBX3A1twhlMeta } from "/home/runner/work/mp-landing-pages/mp-landing-pages/pages/plans/index.vue?macro=true";
import { default as personalgtFTdNURIsMeta } from "/home/runner/work/mp-landing-pages/mp-landing-pages/pages/plans/personal.vue?macro=true";
import { default as redeemG73JuF6myoMeta } from "/home/runner/work/mp-landing-pages/mp-landing-pages/pages/redeem.vue?macro=true";
import { default as registerSJsITicyDcMeta } from "/home/runner/work/mp-landing-pages/mp-landing-pages/pages/register.vue?macro=true";
export default [
  {
    name: "account-academy",
    path: "/account/academy",
    meta: academyWMW5PTXKDhMeta || {},
    component: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/pages/account/academy.vue")
  },
  {
    name: "account-business",
    path: "/account/business",
    meta: indexPuPRFpnlO5Meta || {},
    component: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/pages/account/business/index.vue")
  },
  {
    name: "account-collections",
    path: "/account/collections",
    meta: collectionsLdorU2Gqg3Meta || {},
    component: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/pages/account/collections.vue")
  },
  {
    name: "account-downloads",
    path: "/account/downloads",
    meta: downloadsJcYCPpPBPzMeta || {},
    component: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/pages/account/downloads.vue")
  },
  {
    name: "account",
    path: "/account",
    meta: indexPc36yBXA6NMeta || {},
    component: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/pages/account/index.vue")
  },
  {
    name: "account-subscription",
    path: "/account/subscription",
    meta: subscriptionIy9DX6KglyMeta || {},
    component: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/pages/account/subscription.vue")
  },
  {
    name: "bar",
    path: "/bar",
    component: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/pages/bar.vue")
  },
  {
    name: "checkout-sessionId",
    path: "/checkout/:sessionId()",
    component: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/pages/checkout/[sessionId].vue")
  },
  {
    name: "foo",
    path: "/foo",
    component: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/pages/foo.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/pages/forgot-password.vue")
  },
  {
    name: "hdris",
    path: "/hdris",
    component: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/pages/hdris.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/pages/index.vue")
  },
  {
    name: "licensing",
    path: "/licensing",
    component: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/pages/licensing.vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/pages/login/index.vue")
  },
  {
    name: "login-sso",
    path: "/login/sso",
    component: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/pages/login/sso.vue")
  },
  {
    name: "logout",
    path: "/logout",
    meta: logout6NR3Dxn1VZMeta || {},
    component: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/pages/logout.vue")
  },
  {
    name: "plans-planId",
    path: "/plans/:planId()",
    component: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/pages/plans/[planId].vue")
  },
  {
    name: "plans-commercial",
    path: "/plans/commercial",
    component: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/pages/plans/commercial.vue")
  },
  {
    name: "plans",
    path: "/plans",
    component: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/pages/plans/index.vue")
  },
  {
    name: "plans-personal",
    path: "/plans/personal",
    component: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/pages/plans/personal.vue")
  },
  {
    name: "redeem",
    path: "/redeem",
    component: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/pages/redeem.vue")
  },
  {
    name: "register",
    path: "/register",
    component: () => import("/home/runner/work/mp-landing-pages/mp-landing-pages/pages/register.vue")
  }
]